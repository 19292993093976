.table {
    margin-top: 20px;
    overflow: scroll;
    color: #6a5d5d;
    background-color: white;
    height: 400px;
    overflow-x: hidden;
    border: 1px solid #d5cccc;
  }
  
  .table > div:nth-of-type(odd) {
    background-color: #f3f2f8;
  }

  .table__view {
    display: flex;
    justify-content: space-between;
    overflow-x: hidden;
    padding: 10px;
  }

  .table__countryName {
    flex: 1;
  }
