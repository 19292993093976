.infoBox {
  flex: 1;
}

.infoBox:not(:last-child) {
  margin-right: 10px;
  background: #595959;
}

.infoBox:last-child {
  margin-right: 10px;
  background: #595959;
}

.infoBox--selected {
  border-top: 10px solid greenyellow;
}

.infoBox--red {
  border-color: #ff889f;
}


.infoBox__cases--green {
  color: lightgreen !important;
}

.infoBox__cases {
  color: #ff889f;
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

.infoBox__total {
  color: rgba(255, 255, 255, 0.54);
  font-weight: 700 !important;
  font-size: 0.8rem !important;
  margin-top: 15px !important;
}

.infoBox__title {
  color: rgba(255, 255, 255, 0.54)
}

.infoBox:hover {
  transform: scale(1.02) !important;
  -webkit-box-shadow: 0px 2px 8px 2px rgba(158,158,158,1);
-moz-box-shadow: 0px 2px 8px 2px rgba(158,158,158,1);
box-shadow: 0px 2px 8px 2px rgba(158,158,158,1);
}